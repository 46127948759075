import React from "react";
import { withTranslation } from "react-i18next";

class Footer extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div>
        <footer class="footer">
    
            <div class="social">
                <a href="https://www.linkedin.com/company/open-mindit/"><i class="fab fa-linkedin fa-2x"></i></a>
            </div>
            <p>{t("txt_FooterWriteUs")} <br /> <span class="text-blue">comercial@openmind-it.com</span></p>
            <br /> 
            <div class="social">  <img className="" src="images/omt-logo-no-name.png" alt="Open Mind IT"/></div> 
            <p>Copyright &copy; 2020</p>   
        </footer>
      </div>
    );
  }
}

export default withTranslation("translations")(Footer);