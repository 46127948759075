
import React from "react";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import * as typeformEmbed from '@typeform/embed';


class Header extends React.Component {
  state = {
    value: "en",
    redirect: null
  };

  englishURL = {
    value: "https://form.typeform.com/to/lMSRy8eJ?typeform-medium=embed-snippet"
  };
  portugueseURL = {
    value: "https://form.typeform.com/to/Rphf78t3?typeform-medium=embed-snippet"
  };
  spanishURL = {
    value: "https://form.typeform.com/to/r134bvvH?typeform-medium=embed-snippet"
  };


  handleChange = event => {
    console.log("selected val is ", event.target.value);
    let newlang = event.target.value;
    this.setState(prevState => ({ value: newlang }));
    console.log("state value is", newlang);
    this.props.i18n.changeLanguage(newlang);
  };

   getPopupLayout(url) {
    return  typeformEmbed.makePopup(url,{mode:'popup', hideHeaders:true, hideFooter:true, onSubmit:()=>{}});
  }

  openQuoteByLanguague = () =>{ 
    let popup;

    if(this.state.value === 'en'){
      popup = this.getPopupLayout(this.englishURL.value);
    }else if (this.state.value ==='es'){
      popup = this.getPopupLayout(this.spanishURL.value);
    }else{
      popup = this.getPopupLayout(this.portugueseURL.value);
    }
    popup.open();
  };

  componentDidMount() {
    const navbar = document.getElementById('navbar');
    let scrolled = false;

    window.onscroll = function () {
      if (window.pageYOffset > 10) {
        navbar.classList.remove('top');
        if (!scrolled) {
          navbar.style.transform = 'translateY(-70px)'
          navbar.style.boxShadow = "0px 0px"
          navbar.style.visibility = "visible"
        }
        setTimeout(function () {
          navbar.style.transform = 'translateY(0)';
  
          navbar.style.visibility = "show"
          scrolled = true;
        }, 10);
      } else {
        navbar.classList.add('top');
        navbar.style.boxShadow = "0px 0px"
        navbar.style.visibility = "hidden"
        scrolled = false;
      }
    };

    // Smooth Scrolling
    $('#navbar a, #go-to-quote-btn').on('click', function (e) {
      if (this.hash !== '') {
        e.preventDefault();

        const hash = this.hash;

        $('html, body').animate(
          {
            scrollTop: $(hash).offset().top - 50,
          },
          800
        );
      }
    });
  }

  render() {
    const { t } = this.props;

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} /> 
    }

    return (
      <header className="hero">
        <div id="navbar" className="navbar top">  
          <a href="#home" className="logo-link logo-color"><img className="omt-logo-no-name" src="images/omt-logo-no-name-color.png" alt="Open Mind IT"/></a>
          <a href="#home" className="logo-link logo-color-mobile"><img className="omt-logo-no-name-mobile" src="images/omt-logo-no-name-color-mobile.png" alt="Open Mind IT"/></a>
          <nav>
            <ul>
              <li><a href="#home" className="home-menu-option">{t("txt_NavbarHome")}</a></li>
              <li><a href="#about">{t("txt_NavbarAbout")}</a></li>
              <li><a href="#services">{t("txt_NavbarServices")}</a></li>
              <li><a href="#team">{t("txt_NavbarTeam")}</a></li>      
              <li><a onClick={this.openQuoteByLanguague}>{t("txt_NavbarQuote")}</a></li>
              <div class="dropdown">
                <li><a className="language-list">{t("txt_NavbarLanguage")} <i className="fas fa-angle-down"></i></a></li>
                <div class="dropdown-content">
                  <form>
                    <div>                      
                        <input id="english" type="radio" name="radgroup" value="en" onChange={this.handleChange} />
                        <label for="english">{t("txt_English")}</label>
                    </div>
                    <div >
                      
                        <input id="spanish" type="radio" name="radgroup" value="es" onChange={this.handleChange} />
                        <label for="spanish">{t("txt_Spanish")}</label>
                    </div>
                    <div>                      
                        <input id="portuguese" type="radio" name="radgroup" value="pt" onChange={this.handleChange} />
                        <label for="portuguese">{t("txt_Portuguese")}</label>
                    </div>
                  </form>
                </div>
              </div>
            </ul>
          </nav> 
        </div>

        <div className="content">   
          <h1 className="header primary-light-text"><img  data-aos="fade-down"  data-aos-duration="3000" className="omt-logo-white" src="images/home/logo_header.png" alt='Open Mind IT'/>   </h1>

          



          <p>{t("txt_HomeTitleDescription")}</p> 
          <a id="go-to-quote-btn" href="#services" data-aos="fade-up"  data-aos-duration="3000" className="btn btn-secondary btnnew border-btn ">{t("txt_OurService")} <i className="fas fa-chevron-down"></i></a>
        </div>
      </header> 
    );
  }
}

export default withTranslation("translations")(Header);