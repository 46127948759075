import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: {
        txt_NavbarHome: "Home",
        txt_NavbarAbout: "About",
        txt_NavbarServices: "Services",
        txt_NavbarTeam: "Team",
        txt_NavbarContact: "Contact",
        txt_NavbarQuote: "Quote",
        txt_NavbarLanguage: "Language",
        txt_HomeTitle: "Open Mind IT",
        txt_HomeSubtitle: "We are specialized in developing technological solutions",
        txt_ServiceWeb: "We have experience in providing web application in varios areas, from simple landing sites to sophisticated e-commerces that serve your business to the world.",
        txt_ServiceMobile: "We are masters at developing mobile applications with either native technologies from Apple and Google, or hybrid models where both systems share the same code.",
        txt_ServiceUIUX: "It's very often that we identify a need in the market or a service for our business in which a software application is the main deliverer, but we don't know how to organize this mesh of ideas into a blueprint to develop. Creating a modern and user-friendly UI (User Interface), and a seamless UX (User Experience) is a key step to set your idea into a successful path, and we make sure to work this for you if needed.",
        txt_quoteMessage:"Ask us for a quote if you have an idea you would like to talk about.",
        txt_OurService:"Our Services",
        txt_OurServiceDescription:"Ensure your success with our structure",
        txt_WebDevelopment:"Web Development",
        txt_MobileDevelopment:"Mobile Development",
        txt_BackendEnviroment:"Back-end Environment",
        txt_WhoWeAre:"Who we are",
        txt_OurProfessionalTeam:"Our Professional Team",
        txt_WriteUs:"Write Us!",
        txt_Name:"Name",
        txt_Email:"Email",
        txt_Phone:"Phone",
        txt_Message:"Message",
        txt_MessagePlaceHolder:"Let us know how can we help.",
        txt_Send:"Send",
        txt_HomeTitleDescription:"Custom technological solutions",
        txt_English:"English",
        txt_Spanish:"Español",
        txt_Portuguese:"Português",
        txt_AboutOpenMind:"We are a technology company specialized in developing customized solutions.",
        txt_AboutOpenMind2:"Our goal is to offer the best results from an understanding of the needs and expectations of each client, aiming at performance, safety, innovation and excellence for your business.",
        txt_AboutOpenMind3:"Open's team of specialists is multidisciplinary, has extensive experience in several segments, is 100% committed to the project in execution and is ready to solve great challenges.",
        txt_Maintenance:"Maintenance",
        txt_MaintanceDescription:"Operating systems are constantly changing and the Open Mind team is able to perform possible regular updates and make corrections to possible bugs. Our support is differentiated and widely available according to the criticality of the application, either 8x5 or 24x7.",
        txt_UIUX:"UI & UX Design",
        txt_ServiceBackEnd:"We work with many technologies to build a robust and custom backend to power your platform, application and web.",
        txt_BackEnd:"Back-end",
        txt_TeamFounder: "Founder",
        txt_TeamCoFounder: "Co-Founder",
        txt_Partner: "Partner",
        txt_TeamDescription1: "Bachelor's in Communication with concentration on Advertising and Marketing, aspires to impactful entrepreneurship and has passion for animals.",
        txt_TeamDescription2: "Graduated from Information Systems. His favorite music is Samba and loves playing guitar.",
        txt_TeamDescription3: "Bachelor's in Computer Science, pianist for many years and likes to travel.",
        txt_FooterWriteUs: "Write us any question you may have at:"
      }
    },

    es: {
      translations: {
        txt_NavbarHome: "Inicio",
        txt_NavbarAbout: "Nosotros",
        txt_NavbarServices: "Servicios",
        txt_NavbarTeam: "Equipo",
        txt_NavbarContact: "Contact",
        txt_NavbarQuote: "Cotizar",
        txt_NavbarLanguage: "Lenguaje",
        txt_HomeTitle: "Open Mind IT",
        txt_HomeSubtitle: "Estamos especializados en desarrollar soluciones tecnológicas",
        txt_ServiceWeb: "Tenemos experiencia en proveer aplicaciónes web en varias áreas, desde simples páginas de presentación hasta e-commerces sofisticados que atienden su negocio hacia el mundo.",
        txt_ServiceMobile: "Somos maestros en el desarrollo de aplicaciones móviles tanto con tecnologías nativas de Apple y Google, como con modelos híbridos en donde ambos sistemas comparten el mismo código.",
        txt_ServiceUIUX: "A menudo identificamos una necesidad en el mercado o un servicio para nuestro negocio en el que una aplicación de software es el principal repartidor, pero no sabemos cómo organizar éste conglomerado de ideas en un plan a desarrollar. Crear una IU (Interfaz de usuario) moderna y fácil de usar, y una UX (Experiencia de usuario) perfecta es un paso clave para encaminar su idea hacia el éxito, y nos aseguramos de trabajar estos diseños para usted si es necesario.",
        txt_quoteMessage:"Pídenos una cotización si tiene una idea de la que te gustaría hablar.",
        txt_OurService:"Nuestros servicios",
        txt_OurServiceDescription:"Asegure su éxito con nuestra estructura",
        txt_WebDevelopment:"Desarrollo web",
        txt_MobileDevelopment:"Desarrollo móvil",
        txt_BackendEnviroment:"Entornos de back-end",
        txt_WhoWeAre:"Quienes somos",
        txt_OurProfessionalTeam:"Nuestro equipo profesional",
        txt_WriteUs:"¡Escríbenos!",
        txt_Name:"Nombre",
        txt_Email:"Email",
        txt_Phone:"Teléfono",
        txt_Message:"Mensaje",
        txt_MessagePlaceHolder:"Déjanos saber como podemos ayudar.",
        txt_Send:"Enviar",
        txt_HomeTitleDescription:"Soluciones tecnológicas customizadas",
        txt_English:"English",
        txt_Spanish:"Español",
        txt_Portuguese:"Português",
        txt_AboutOpenMind:"Somos una empresa tecnológica especializada en desarrollar soluciones a medida.",
        txt_AboutOpenMind2:"Nuestro objetivo es ofrecer los mejores resultados a partir de la comprensión de las necesidades y expectativas de cada cliente, apuntando al desempeño, seguridad, innovación y excelencia para su negocio.",
        txt_AboutOpenMind3:"El equipo de especialistas de Open es multidisciplinario, contando con amplia experiencia en varios segmentos, está 100% comprometido con el proyecto en ejecución y listo para resolver grandes desafíos.",
        txt_MaintanceDescription:"Los sistemas operativos cambian constantemente y el equipo de Open Mind puede realizar posibles actualizaciones periódicas y corregir posibles errores. Nuestro soporte es diferenciado y ampliamente disponible según la criticidad de la aplicación, ya sea 8x5 o 24x7.",
        txt_Maintenance:"Mantenimiento",
        txt_UIUX:"UI & UX Design",
        txt_ServiceBackEnd:"Trabajamos con muchas tecnologías para construir un back-end robusto y personalizado para potenciar su plataforma, aplicación y web.",
        txt_BackEnd:"Back-end",
        txt_TeamFounder: "Fundador",
        txt_TeamCoFounder: "Co-Fundador",
        txt_Partner: "Afiliado",
        txt_TeamDescription1: "Bachillerato en Cominucaciones con concentración en Publicidad y Mercadeo, aspirante al emprendimiento de impacto y apasionada por los animales.",
        txt_TeamDescription2: "Graduado en Sistemas de Información. Samba es su música favorita y adora tocar guitarra.",
        txt_TeamDescription3: "Bachillerato en Ciencias da Computadoras, pianista por muchos años y gusta viajar.",
        txt_FooterWriteUs: "Escríbenos cualquier pregunta que tengas a:"
      }
    },

    pt: {
      translations: {
        txt_NavbarHome: "Home",
        txt_NavbarAbout: "Sobre nós",
        txt_NavbarServices: "Serviços",
        txt_NavbarTeam: "Time",
        txt_NavbarContact: "Contato",
        txt_NavbarQuote: "Cotação",
        txt_NavbarLanguage: "Língua",
        txt_HomeTitle: "Open Mind IT",
        txt_ServiceWeb: "Temos experiência no fornecimento de aplicativos da web em várias áreas, desde simples sites de desembarque até sofisticados e-commerces que atendem ao seu negócio para todo o mundo.",
        txt_ServiceMobile: "Somos mestres no desenvolvimento de aplicativos com tecnologia nativa de Apple e Google, ou em modelos híbridos em que ambos os sistemas compartilham o mesmo código.",
        txt_ServiceUIUX: "Muitas vezes identificamos uma necessidade de mercado ou um serviço para o nosso negócio em que um aplicativo de software é a parte principal a ser criada, mas não sabemos como organizar estas idéias em um plano a ser desenvolvido. Criar uma IU (Interface do Usuário) moderna e amigável e uma UX (Experiência do Usuário) intuitiva é uma etapa fundamental que sua ideia tenha um caminho de sucesso e garantimos trabalhar isso para você, quando necessário.",
        txt_quoteMessage:"Peça-nos um orçamento se tiver uma ideia sobre a qual gostaria de falar.",
        txt_OurService:"Nossos Serviços",
        txt_OurServiceDescription:"Garanta seu sucesso com nossa estrutura",
        txt_WebDevelopment:"Desenvolvimento Web",
        txt_MobileDevelopment:"Desenvolvimento Mobile",
        txt_BackendEnviroment:"Back-end",
        txt_WhoWeAre:"Quem Somos",
        txt_OurProfessionalTeam:"Nosso time",
        txt_WriteUs:"Escreva pra gente!",
        txt_Name:"Nome",
        txt_Email:"Email",
        txt_Phone:"Telefone",
        txt_Message:"Mensagem",
        txt_MessagePlaceHolder:"Deixe-nos saber como podemos ajudar.",
        txt_Send:"Enviar",
        txt_HomeTitleDescription:"Soluções tecnológicas customizadas",
        txt_English:"English",
        txt_Spanish:"Español",
        txt_Portuguese:"Português",
        txt_AboutOpenMind:"Somos uma empresa de tecnologia especializada no desenvolvimento de soluções customizadas.",
        txt_AboutOpenMind2:"Nosso objetivo é oferecer os melhores resultados a partir do entendimento das necessidades e expectativas de cada cliente, visando performance, segurança, inovação e excelência para o seu negócio.",
        txt_AboutOpenMind3:"A equipe de especialistas da Open é multidisciplinar, possui larga experiência em diversos segmentos, está 100% comprometida com o projeto em execução e pronta para resolver grandes desafios.",
        txt_MaintanceDescription:"Sistemas operacionais sofrem mudanças constantes e o time da Open Mind está apto a executar possíveis atualizações regulares e realizar correções de possíveis bugs. Nosso suporte é diferenciado e amplamente disponível de acordo com a criticidade da aplicação,seja 8x5 ou 24x7.",
        txt_Maintenance:"Manutenção",
        txt_UIUX:"UI & UX Design",
        txt_ServiceBackEnd:"Trabalhamos com muitas tecnologias para construir um back-end robusto e personalizado para potencializar sua plataforma, aplicativo e web.",
        txt_BackEnd:"Back-end",
        txt_TeamFounder: "Founder",
        txt_TeamCoFounder: "Co-Founder",
        txt_Partner: "Sócio",
        txt_TeamDescription1: "Bacharela em Comunicação Social com habilitação em Publicidade e Propaganda, aspirante por empreendedorismo de impacto e apaixonada por animais.",
        txt_TeamDescription2: "Graduado em Sistemas de informação. Samba é minha musica favorita e adoro tocar violão.",
        txt_TeamDescription3: "Bacharela em Ciências da Computação, pianista por muitos anos e gosta viajar.",
        txt_FooterWriteUs: "Alguma pergunta? Escreva pra gente:"
      }
    },
  },
  fallbackLng: "en",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;