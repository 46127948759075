import React from "react";
import { withTranslation } from "react-i18next";
import * as typeformEmbed from '@typeform/embed';

class Main extends React.Component {
    state = {
        value: "en",
        redirect: null
      };
    
      englishURL = {
        value: "https://form.typeform.com/to/lMSRy8eJ?typeform-medium=embed-snippet"
      };
      portugueseURL = {
        value: "https://form.typeform.com/to/Rphf78t3?typeform-medium=embed-snippet"
      };
      spanishURL = {
        value: "https://form.typeform.com/to/r134bvvH?typeform-medium=embed-snippet"
      };

      handleChange = event => {
        console.log("selected val is ", event.target.value);
        let newlang = event.target.value;
        this.setState(prevState => ({ value: newlang }));
        console.log("state value is", newlang);
        this.props.i18n.changeLanguage(newlang);
      };

      getPopupLayout(url) {
        return  typeformEmbed.makePopup(url,{mode:'popup', hideHeaders:true, hideFooter:true, onSubmit:()=>{}});
      }

    openQuoteByLanguague = () =>{ 
    let popup;    
        if(this.state.value =='en'){
          popup = this.getPopupLayout(this.englishURL.value);
        }else if (this.state.value =='es'){
          popup = this.getPopupLayout(this.spanishURL.value);
        }else{
          popup = this.getPopupLayout(this.portugueseURL.value);
        }
        popup.open();
      };

    render() {
        const { t } = this.props;

        return (
            <main>
                <section id="about" class="about flex-columns">
                    <div class="row">
                        <div class="column">
                            <div class="column-2 bg-primary-dark section-title">
                                <h2 class="text-center" data-aos="fade-left"  data-aos-duration="3000">{t("txt_NavbarAbout")}</h2> 
                                <h3 class="text-center" data-aos="fade-left"  data-aos-duration="3000">{t("txt_AboutOpenMind")}</h3>   
                                <p data-aos="fade-left"  data-aos-duration="3000">{t("txt_AboutOpenMind2")}</p>
                                <p data-aos="fade-left"  data-aos-duration="3000">{t("txt_AboutOpenMind3")}</p>
                            </div>
                        </div>
                        <div class="column">
                            <div class="column-1">
                                <img src="images/home/office1.jpg" alt="capture ideas" />
                            </div>
                        </div>
                    </div>
                </section>

                <section id="services" className="services"  >
                    <header className="section-header section-title" data-aos="fade-up">
                        <h2>{t("txt_OurService")}</h2> 
                    </header>





                    <div class="row">
                        <div class="col-33">
                        <div class="m10"  data-aos="fade-up" data-aos-duration="3000">
                            <div class="service-box blue">
                                <i class="fas fa-window-restore icon"></i>
                                <h3>{t("txt_WebDevelopment")}</h3>
                                <p>{t("txt_ServiceWeb")}</p>
                            </div>
                            
                            </div>
                        </div>
                        <div class="col-33">
                        <div class="m10"  data-aos="fade-up"  data-aos-duration="3000">
                            <div class="service-box orange">
                                <i class="fas  fa-mobile-alt icon"></i>
                                <h3>{t("txt_MobileDevelopment")}</h3>
                                <p>{t("txt_ServiceMobile")}</p>
                            </div>

                         
  


                            </div>
                        </div>
                        <div class="col-33">
                        <div class="m10"  data-aos="fade-up"  data-aos-duration="3000">
                            <div class="service-box green">
                                <i class="fas fa-cloud-upload-alt icon"></i>
                            
        <h3>{t("txt_BackEnd")}</h3>
        <p>{t("txt_ServiceBackEnd")}</p> 
                            </div>
                            </div>
                        </div>
                        <div class="col-50">
                        <div class="m10" data-aos="fade-up"  data-aos-duration="3000">
                            <div class="service-box red">
                            <i className="fas fa-pencil-ruler icon"></i> 
   
   <h3>{t("txt_UIUX")}</h3>
   <p>{t("txt_ServiceUIUX")}</p>
                            </div>
                            </div>
                        </div>

                    <div class="col-50">  
                    <div class="m10"  data-aos="fade-up"  data-aos-duration="3000">
                    <div class="service-box purple">
                    <i className="fas fa-toolbox icon"></i>
   
   <h3>{t("txt_Maintenance")}</h3>
   <p>{t("txt_MaintanceDescription")}</p>
                            </div>
                            </div>
                            
                    </div>

                    
                </div>



                    







                   
        
         



<div class="clearfix"></div>


     
                    <div className="service-call-to-action">
                        <h3 data-aos="fade-down"  data-aos-duration="3000">{t("txt_quoteMessage")}</h3>
                        <a data-aos="fade-down"  data-aos-duration="3000" onClick={this.openQuoteByLanguague} className="btn btn-secondary btnnew border-btn">{t("txt_NavbarQuote")}</a> 
                      
                    </div>
                </section>

                <section id="team" className="team section-padding bg-primary">
   

                    <header className="section-header section-title">
                        <h2 data-aos="fade-down"  data-aos-duration="3000" class="text-dark">{t("txt_OurProfessionalTeam")}</h2> 
                    </header> 


                    <div class="row">
                        <div class="col-50 d-flex align-items-stretch">
                        <div class="m10" data-aos="fade-down-right"  data-aos-duration="3000">
                            <div class="member">
                                <div class="member-img"> 
                                <img src="images/team/daiane.jpeg" class="img-fluid" alt="" />  
                 
                                    <div class="member-info">  
                                        <h4>Daiane Duarte</h4>
                                        <span>{t("txt_TeamCoFounder")}</span>
                                        <p>{t("txt_TeamDescription1")}</p>
                                    </div> 
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="col-50 d-flex align-items-stretch"> 
                        <div class="m10" data-aos="fade-down-left"  data-aos-duration="3000">
                            <div class="member">
                                <div class="member-img">
                                <img src="images/team/gustavo.jpeg" class="img-fluid" alt="" />
                                
                       
                                    <div class="member-info">
                                        <h4>Gustavo Dorea</h4>
                                        <span>{t("txt_TeamCoFounder")}</span>
                                        <p>{t("txt_TeamDescription2")}</p>

                                        
                                    </div> 
                                </div>
                            </div>
                            </div>
                        </div>
                        

                    </div>



      






















              

                </section>
            </main>
        );
    }
}

export default withTranslation("translations")(Main);