import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import './App.scss';
import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "center"
  },
  formControl: {
    margin: theme.spacing.unit * 3
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
    flexDirection: "row"
  },
  container: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  topgrid: {
    marginTop: "10px",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center"
  }
});

class App extends Component {
  state = {
    value: "en"
  };

  handleChange = event => {
    console.log("selected val is ", event.target.value);
    let newlang = event.target.value;
    this.setState(prevState => ({ value: newlang }));
    console.log("state value is", newlang);
    this.props.i18n.changeLanguage(newlang);
  };

  render() {
    return (
      <div className="App">
        <Header />
        <Main />
        <Footer />
      </div>
    );
  }
}

// extended main view with translate hoc

App.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withTranslation("translations")(App));